<template>
    <slot />
</template>

<script lang="ts" setup>
import {
    type CoreSwiperWrapperProvide,
    SymbolCoreSwiperWrapper
} from '@core/app/composables/components'
import defu from 'defu'
import Swiper from 'swiper'
import type { SwiperOptions } from 'swiper/types'
import { Scrollbar } from 'swiper/modules'
// @ts-ignore - Swiper doesn't expose these types publicly
import type { ScrollbarOptions } from 'swiper/types/modules/scrollbar'

const _swiper: Ref<Swiper | null> = ref(null)
const _swiperEl: Ref<HTMLElement | null> = ref(null)
let _swiperOptions: SwiperOptions | undefined
let _scrollbarOptions: ScrollbarOptions | undefined
const _swiperParent: Ref<Swiper | null | undefined> = ref(null)
const _numberOfSlides = ref<number>(0)
const _activeSlideIndex = ref<number>(0)

const _callbackSetParent = ref<((parent: Swiper) => void) | null>(null)
const _callbackInitializeThumbs = ref<((swiper: Swiper, parent: Swiper) => void) | null>(null)

const { injected } = useCoreSwiperWrapperProvide()

injected?.callbackSetParent((swiperParent) => {
    _swiperParent.value = swiperParent
})

provide<CoreSwiperWrapperProvide>(SymbolCoreSwiperWrapper, {
    swiper: _swiper,
    swiperParent: _swiperParent,
    numberOfSlides: _numberOfSlides,
    activeSlideIndex: _activeSlideIndex,
    setSwiper: (el: HTMLElement, settings: SwiperOptions) => {
        _swiperEl.value = el
        _swiperOptions = defu(settings, _swiperOptions || {})
    },
    setScrollbar: (options: ScrollbarOptions, swiperOptions) => {
        _scrollbarOptions = options
        if (swiperOptions) {
            _swiperOptions = defu(swiperOptions, _swiperOptions)
        }
    },
    setNumberOfSlides: (value: number) => {
        _numberOfSlides.value = value
    },
    callbackSetParent: (callback: (parent: Swiper) => void) => {
        _callbackSetParent.value = callback
    },
    callbackInitializeThumbs: (callback: (swiper: Swiper, parent: Swiper) => void) => {
        _callbackInitializeThumbs.value = callback
    },
})

onMounted(() => {
    if (!_swiperEl.value || !_swiperOptions) return

    _swiperOptions.on ||= {}
    _swiperOptions.on.realIndexChange = (swiper: Swiper) => {
        _activeSlideIndex.value = swiper.realIndex
    }


    // handle scrollbar presence
    if (_scrollbarOptions !== undefined) {
        _swiperOptions.scrollbar = _scrollbarOptions

        // install the module
        _swiperOptions.modules ||= []
        _swiperOptions.modules.push(Scrollbar)
    }

    // initialize the swiper
    _swiper.value = new Swiper(_swiperEl.value, _swiperOptions)

    _callbackSetParent.value?.(_swiper.value)

    nextTick(() => {
        // TODO: add more flexibility
        if (_swiperParent.value) {
            _callbackInitializeThumbs.value?.(_swiper.value!, _swiperParent.value)
        }
    })
})

</script>

<style lang="scss" scoped>

</style>
